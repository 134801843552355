import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

import Home from '../Home/Home';
import Pickings from '../Pickings/Pickings';
import Processed from '../Processed/Processed';
import Packings from '../Packings/Packings';
import Dispatchs from '../Dispatchs/Dispatchs';
import Returns from '../Returns/Returns';
import Cancelled from '../Cancelled/Cancelled';
import Incomplete from '../Incomplete/Incomplete';
import Tools from '../Tools/Tools';

import GenericNotFound from './GenericNotFound';

const AnimatedPage = () => {
    return (
      <Container fluid className="siteContent m-0 p-0">

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/pickings" element={<Pickings />} />
                <Route path="/packings" element={<Packings />} />
                <Route path="/dispatchs" element={<Dispatchs />} />
                <Route path="/processed" element={<Processed />} />
                <Route path="/returned" element={<Returns />} />
                <Route path="/cancelled" element={<Cancelled />} />
                <Route path="/incomplete" element={<Incomplete />} />
                <Route path="/tools" element={<Tools />} />

                <Route path="*" element={<GenericNotFound />} />
            </Routes>

    </Container>
  );
}

export default AnimatedPage;